/* eslint-disable camelcase */
import React, { useRef, useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { httpsCallable } from 'firebase/functions';
import useGlobal from 'global-state/store';
import { useFunctions } from 'firebaseHooks/FunctionsContext';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import useFirestoreDocumentData from 'firebaseHooks/useFirestoreDocumentData';
import useMenu from 'menu-actions/useMenu';
import MyCollapse from './components/MyCollapse';
import Action from './components/Action';

export default function PrivateVehicleTransactionDossier({
  dossier, user,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setreturnAction } = useMenu();
  const functions = useFunctions();
  functions.region = 'europe-west1';
  const contractCall = httpsCallable(functions, 'createContractPdfCall');
  const [, globalActions] = useGlobal();
  const [pageStatus, setPageStatus] = useState('initial');
  const ownerCollapse = useRef(null);
  const acquirerCollapse = useRef(null);
  const formalitiesCollapse = useRef(null);

  useEffect(() => {
    setreturnAction(() => () => navigate('/app/dossiers', { replace: true }));
  }, [navigate, setreturnAction]);

  const isSeller = user.uid === dossier.seller;

  const {
    data: sellerForm,
    loadingState: sellerFormLoadingState,
  } = useFirestoreDocumentData(`dossiers/${dossier.id}/${isSeller ? 'forms' : 'formViews'}/sellerForm`);
  const {
    data: acquirerForm,
    loadingState: acquirerFormLoadingState,
  } = useFirestoreDocumentData(`dossiers/${dossier.id}/${isSeller ? 'formViews' : 'forms'}/acquirerForm`);
  const {
    data: sellerSaleFormalitiesForm,
    loadingState: sellerSaleFormalitiesFormLoadingState,
  } = useFirestoreDocumentData(`dossiers/${dossier.id}/${isSeller ? 'forms' : 'formViews'}/sellerSaleFormalitiesForm`);

  // console.log('acquirerForm data: ', acquirerForm);

  const expandIcon = (state) => (state ? <ExpandLessIcon /> : <ExpandMoreIcon />);

  const handleContractCall = async () => {
    setPageStatus('loading');
    try {
      await contractCall({
        userUid: user.uid,
        contractUid: dossier.id,
      });

      handleGoToContract();
    } catch (error) {
      globalActions.setSnackbarMessage({ message: `${t('unexpected_error')} ${error}`, severity: 'error' });
    } finally {
      setPageStatus('done');
    }
  };

  const handleGoToContract = async () => {
    const storageDocPath = `gs://autonoom-ed849.appspot.com/dossiers/${dossier.id}/contrat-autonoom.pdf`;
    navigate('contrat-pdf', {
      state: {
        dossier,
        storageDocPath,
      },
    });
  };

  // Possible states :

  // seller_draftPaymentPending
  // seller_draftPaymentSuccess
  // seller_draftPaymentFailed
  // seller_sellerFormCompleted
  // seller_dossierSharedToAcquirer
  // seller_acquirerAcceptedShare
  // seller_acquirerFormCompleted
  // seller_sellFormalitiesFormCompleted
  // seller_dossierCompleted

  // acquirer_draftPaymentPending
  // acquirer_draftPaymentSuccess
  // acquirer_draftPaymentFailed
  // acquirer_dossierSharedToSeller
  // acquirer_sellerAcceptedShare
  // acquirer_sellerFormCompleted
  // acquirer_acquirerFormCompleted
  // acquirer_sellFormalitiesFormCompleted
  // acquirer_dossierCompleted

  const showModifySellerForm = () => user
    && user.uid === dossier.seller
    && !['seller_draftPaymentPending', 'acquirer_draftPaymentPending'].includes(dossier.state);

  const showModifyAcquirerForm = () => user
    && user.uid === dossier.acquirer
    && !['acquirer_acquirerFormCompleted'].includes(dossier.state);

  const showActions = () => !['seller_draftPaymentPending', 'seller_draftPaymentFailed',
    'acquirer_draftPaymentPending', 'acquirer_draftPaymentFailed'].includes(dossier.state);

  const showError = () => ['seller_draftPaymentFailed', 'acquirer_draftPaymentFailed',
    'seller_draftPaymentPending', 'acquirer_draftPaymentPending'].includes(dossier.state);

  const [sellerFormExpanded, setSellerFormExpanded] = useState(false);
  const [acquirerFormExpanded, setAcquirerFormExpanded] = useState(false);
  const [formalitiesFormExpanded, setFormalitiesFormExpanded] = useState(false);

  const showGoToSellerForm = () => user
  && (user.uid === dossier.seller)
  && ['seller_draftPaymentSuccess', 'acquirer_dossierSharedToSeller'].includes(dossier.state);

  const goToSellerForm = () => {
    navigate('formulaire-vendeur', {
      state: {
        dossierId: dossier.id,
        successState: 'seller_dossierSharedToAcquirer',
        acquirerEmail: dossier.acquirerEmail,
      },
    });
  };

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', gap: 2, px: 1, maxWidth: '99vw', mb: 15,
    }}
    >

      {sellerFormLoadingState === 'success' && sellerForm && (
        <MyCollapse
          theRef={ownerCollapse}
          dossier={dossier}
          setExpand={setSellerFormExpanded}
          expanded={sellerFormExpanded}
          expandIcon={expandIcon}
          title={t('contract.info_entered_by_seller')}
          form={sellerForm}
          formType="saleForm"
          modifyCondition={showModifySellerForm}
          borderColor="secondary.main"
        />
      )}

      {acquirerFormLoadingState === 'success' && acquirerForm && (
        <MyCollapse
          theRef={acquirerCollapse}
          dossier={dossier}
          setExpand={setAcquirerFormExpanded}
          expanded={acquirerFormExpanded}
          expandIcon={expandIcon}
          title={t('contract.info_entered_by_acquirer')}
          form={acquirerForm}
          formType="acquirerForm"
          modifyCondition={showModifyAcquirerForm}
          borderColor="link.main"
          additionalForm={sellerForm}
        />
      )}

      {sellerSaleFormalitiesFormLoadingState === 'success' && sellerSaleFormalitiesForm && (
        <MyCollapse
          theRef={formalitiesCollapse}
          dossier={dossier}
          setExpand={setFormalitiesFormExpanded}
          expanded={formalitiesFormExpanded}
          expandIcon={expandIcon}
          title={t('sellerSaleFormalitiesForm.title')}
          form={sellerSaleFormalitiesForm}
          formType="formalitiesForm"
          borderColor="info.main"
        />
      )}

      {showGoToSellerForm() && (
        <Button
          color="primary"
          aria-label="completeSellerForm"
          onClick={goToSellerForm}
          variant="contained"
        >
          {t('contract.complete_seller_part')}
          <ArrowForwardIosIcon />
        </Button>
      )}

      {showActions() && (
        <Action
          user={user}
          pageStatus={pageStatus}
          setPageStatus={setPageStatus}
          handleGoToContract={handleGoToContract}
          dossier={dossier}
          t={t}
          ownerCollapse={ownerCollapse}
          acquirerCollapse={acquirerCollapse}
          formalitiesCollapse={formalitiesCollapse}
          sellerForm={sellerForm}
        />
      )}

      {showError() && (
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 2,
          minHeight: '200px',
        }}
        >
          <Typography variant="body1" color="error">
            Le dossier est dans un état incohérent. Merci de nous contacter.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              navigate('/app/contact');
            }}
          >
            Contact
          </Button>
        </Box>
      )}
    </Box>
  );
}
