import React, { useState } from 'react';
import {
  Box, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import {
  collection, doc, writeBatch,
} from 'firebase/firestore';
import { LoadingButton } from '@mui/lab';
import useGlobal from 'global-state/store';
import {
  getStorage, ref as storageRef,
} from 'firebase/storage';
import { extractFilesFromFormikValues, processAndReplaceFiles, uploadWithTimeout } from 'components/utils';
import emailTemplate from 'components/emails/EmailTemplate';
import { useFirestore } from 'firebaseHooks/FirestoreContext';
import { useAuth } from 'firebaseHooks/AuthContext';
import { validationSchema } from './validationSchema';
import SaleDetails from './fields/SaleDetails';
import SaleFormalities from './fields/SaleFormalities';
import BuyerDetails from './fields/BuyerDetails';
import Vehicle from './fields/Vehicle';
import ScrollToFieldError from '../ScrollToFieldError';
import AcquirerPresentDialog from './AcquirerPresentDialog';
import SavedFormsDialog, { saveFormToFirestore } from '../sellerForm/SavedFormsDialog';

export default function SharedBuyerForm({
  mode, initialValues, dossier, successState, sellerForm,
}) {
  const { t } = useTranslation();
  const firestore = useFirestore();
  const [pageStatus, setPageStatus] = useState('initial');
  const storage = getStorage();
  const [, globalActions] = useGlobal();
  const { currentUser: user } = useAuth();
  const navigate = useNavigate();
  const isReadOnly = mode === 'readonly';
  const [scrollToErrorActive, setScrollToErrorActive] = useState(false);
  const [initialDialogOpen, setInitialDialogOpen] = useState(mode === 'create');
  const [savedFormsDialogOpen, setSavedFormsDialogOpen] = useState(false);

  const handleFormSubmit = () => {
    setScrollToErrorActive(true);
    if (mode === 'create') {
      saveFormToFirestore('savedAcquirerForms', formik.values, user.uid, firestore);
    }
    formik.submitForm();
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema(t),
    onSubmit: (values) => {
      try {
        sendBuyerForm();
      } catch (error) {
        globalActions.setSnackbarMessage({ message: `${t('unexpected_error')} ${error}`, severity: 'error' });
        throw error;
      }
    },
  });

  const uploadFiles = async (files, basePath) => {
    const uploadPromises = files.map(async (fileData) => {
      const fileRef = storageRef(storage, `${basePath}/${fileData.path}/${fileData.file.name}`);
      await uploadWithTimeout(t, fileRef, fileData.file);
    });
    return Promise.all(uploadPromises);
  };

  const sendBuyerForm = async () => {
    setPageStatus('loading');
    try {
      const allFiles = [];

      extractFilesFromFormikValues(formik.values, allFiles);

      const dossierId = dossier.id;
      const acquirerFormDocPath = `dossiers/${dossierId}/forms/acquirerForm`;

      let valuesToStore = await processAndReplaceFiles(formik.values, allFiles, acquirerFormDocPath);
      valuesToStore = { ...valuesToStore };

      const prettyPrinted = JSON.stringify(valuesToStore, null, 2);
      console.log('FINAL VALUES: ', prettyPrinted);

      const batch = writeBatch(firestore);

      const dossierDocRef = doc(firestore, 'dossiers', dossierId);
      const acquirerFormDocRef = doc(firestore, 'dossiers', dossierId, 'forms', 'acquirerForm');
      let dossierData;
      let acquirerFormData;
      if (mode === 'create') {
        dossierData = {
          state: successState,
          acquirer: user.uid,
        };
        acquirerFormData = {
          submitDate: new Date(),
          values: valuesToStore,
        };
        batch.set(acquirerFormDocRef, acquirerFormData);
        batch.update(dossierDocRef, dossierData);
      } else {
        acquirerFormData = {
          submitDate: new Date(),
          values: valuesToStore,
        };
        batch.update(acquirerFormDocRef, acquirerFormData);
      }
      await Promise.all([
        uploadFiles(allFiles, acquirerFormDocPath),
      ]);

      const baseUrl = window.location.origin;
      const path = '/app/dossiers';
      const link = `${baseUrl}${path}`;

      const messageHtml1 = emailTemplate(` <p>${t('contract.acquirer_completed_part_mail_content', {
        vehicleName: dossier.dossierName,
        link: ` <p>
          <a href="${link}"
             style="
               display: inline-block;
               padding: 10px 20px;
               background-color: #14c7bf;
               color: white;
               text-decoration: none;
               border-radius: 5px;
               font-weight: bold;
             ">
              Terminer la création du contrat
          </a>
        </p>`,
        linkText: link,
      })}</p>`);

      const messageHtml2 = emailTemplate(` <p>${t('contract.acquirer_updated_part_mail_content', {
        vehicleName: dossier.dossierName,
        link: ` <p>
          <a href="${link}"
             style="
               display: inline-block;
               padding: 10px 20px;
               background-color: #14c7bf;
               color: white;
               text-decoration: none;
               border-radius: 5px;
               font-weight: bold;
             ">
              Terminer la création du contrat
          </a>
        </p>`,
        linkText: link,
      })}</p>`);

      const mailDocRef = doc(collection(firestore, 'mail'));
      if (mode === 'create') {
        batch.set(mailDocRef, {
          to: dossier.sellerEmail,
          message: {
            subject: t('contract.acquirer_completed_part_mail_title'),
            html: messageHtml1,
          },
        });
      } else {
        batch.set(mailDocRef, {
          to: dossier.sellerEmail,
          message: {
            subject: t('contract.acquirer_updated_part_mail_title'),
            html: messageHtml2,
          },
        });
      }

      await batch.commit();

      if (mode === 'edit') {
        globalActions.setSnackbarMessage({ message: t('success'), severity: 'success', displayDuration: 2000 });

        navigate(-1);
      } else {
        globalActions.setSnackbarMessage({ message: t('success'), severity: 'success', displayDuration: 2000 });
        navigate('/app/dossiers', { replace: true });
      }
    } catch (err) {
      globalActions.setSnackbarMessage({ message: `${t('unexpected_error')} : ${err}`, severity: 'error' });
      throw err;
    } finally {
      setPageStatus('done');
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
        mt: 2,
        width: '90vw',
        maxWidth: 800,
      }}
      component="form"
      onSubmit={formik.handleSubmit}
    >
      <ScrollToFieldError
        formik={formik}
        scrollToErrorActive={scrollToErrorActive}
        setScrollToErrorActive={setScrollToErrorActive}
        formBaseKey="acquirerForm"
        orderedKeys={[]}
      />

      <Typography sx={{ fontWeight: 'bold' }}>
        {`${t('vehicle_sale_contract')}\n${t('vehicle_sale_form_steps.step2')}`}
      </Typography>

      <AcquirerPresentDialog open={initialDialogOpen} setOpen={setInitialDialogOpen} />

      <SaleDetails
        isReadOnly={isReadOnly}
        formik={formik}
        ownerFormValues={sellerForm.values}
        mode={mode}
        bothPartiesPresent={dossier.bothPartiesPresent}
      />
      <Vehicle
        isReadOnly={isReadOnly}
        formik={formik}
      />
      <SaleFormalities
        isReadOnly={isReadOnly}
        formik={formik}
      />
      <BuyerDetails
        isReadOnly={isReadOnly}
        formik={formik}
      />

      {mode === 'create' && (
      <LoadingButton loading={pageStatus === 'loading'} onClick={handleFormSubmit} variant="contained">
        {t('continue')}
      </LoadingButton>
      )}
      {mode === 'edit' && (
      <LoadingButton loading={pageStatus === 'loading'} onClick={handleFormSubmit} variant="contained">
        {t('modify')}
      </LoadingButton>
      )}
      {mode === 'create' && (
        <SavedFormsDialog
          open={savedFormsDialogOpen}
          setOpen={setSavedFormsDialogOpen}
          formik={formik}
          docName="savedAcquirerForms"
        />
      )}

    </Box>
  );
}
