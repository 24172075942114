import React from 'react';
import {
  Box, IconButton, Paper, Typography,
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useTranslation } from 'react-i18next';

export default function DossierListItem({
  itemData, onClick,
}) {
  const { t } = useTranslation();
  const statusColor = () => {
    switch (itemData.state) {
      case 'ownerSubmittedFirstForm':
        return '#437A89';
      case 'completed':
        return '#1db954';
      case 'sellerSaleFormalitiesCompleted':
        return '#1db954';
      default:
        return 'text.primary';
    }
  };

  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: onClick ? 'pointer' : 'default',
        width: '100%',
        p: 3,
        gap: 2,
      }}
      onClick={onClick ? () => onClick(itemData) : undefined}
    >
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: { xs: 'wrap', sm: 'nowrap' },
        gap: 2,
        flexGrow: 1,
      }}
      >
        <Typography sx={{
          fontWeight: 'bold',
          width: { xs: '100%', md: 'auto' },
        }}
        >
          {itemData.dossierName}
        </Typography>

        <Typography
          sx={{
            color: statusColor(),
            width: { xs: '100%', md: 'auto' },
          }}
        >
          {t(`dossier_states.${itemData.state}`)}
        </Typography>
      </Box>

      {onClick && (
        <IconButton size="small">
          <ArrowForwardIosIcon />
        </IconButton>
      )}
    </Paper>
  );
}
