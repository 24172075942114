import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { useFunctions } from 'firebaseHooks/FunctionsContext';
import { httpsCallable } from 'firebase/functions';
import { useFirestore } from 'firebaseHooks/FirestoreContext';
import { useAuth } from 'firebaseHooks/AuthContext';
import {
  collection, Timestamp, writeBatch, doc, arrayUnion,
} from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { getAnalytics, logEvent } from 'firebase/analytics';
import useGlobal from 'global-state/store';
import { useState, useEffect } from 'react';
import { LoadingButton } from '@mui/lab';
import SearchIcon from '@mui/icons-material/Search';
import moment from 'moment';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

interface FormInputs {
  acquirerEmail: string;
  vehicleBrand: string;
  vehicleModel: string;
}

interface DialogFormInputs {
  sharingCode: string;
}

interface StripeCheckoutResponse {
  data: {
    url: string;
  };
}

export default function NewDossier() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInputs>();
  const functions = useFunctions();
  const db = useFirestore();
  const { currentUser } = useAuth();
  functions.region = 'europe-west1';
  const checkoutSession = httpsCallable(functions, 'stripeCheckoutSession');
  const analytics = getAnalytics();
  const [, globalActions] = useGlobal();
  const [pageStatus, setPageStatus] = useState('initial');
  const { currentUser: user } = useAuth();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [sharingCode, setSharingCode] = useState('');

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);

    if (queryParameters.get('canceled')) {
      const dossierId = queryParameters.get('dossierId');
      if (dossierId && db && currentUser) {
        const dossierRef = doc(db, 'dossiers', dossierId);
        // Update dossier state to failed
        writeBatch(db)
          .update(dossierRef, { state: 'seller_draftPaymentFailed' })
          .commit();
      }
      globalActions.setSnackbarMessage({ message: t('subscriptions.order_canceled'), severity: 'error' });
      logEvent(analytics, 'subscriptions_checkout_canceled', {
        appName: 'Autonoom Web',
      });
    } else if (queryParameters.get('success')) {
      const dossierId = queryParameters.get('dossierId');
      if (dossierId) {
        navigate(`/app/dossiers/${dossierId}`, { replace: true });
      }
    }
  }, [analytics, globalActions, navigate, t, db, currentUser]);

  const onSubmit = async (data: FormInputs) => {
    try {
      setPageStatus('loading');

      if (!db || !currentUser) {
        throw new Error('Database or user not initialized');
      }

      // Create batch
      const batch = writeBatch(db);

      // Create dossier reference
      const dossierRef = doc(collection(db, 'dossiers'));
      const dossierData = {
        state: 'seller_draftPaymentPending',
        type: 'vehicleSalePrivateIndividuals',
        owner: currentUser.uid,
        seller: currentUser.uid,
        ownerType: 'seller',
        sellerEmail: user.email,
        acquirerEmail: data.acquirerEmail,
        dossierName: `${data.vehicleBrand} ${data.vehicleModel} - ${moment().format('DD/MM/YYYY HH:mm')}`,
        initialForm: {
          values: { ...data },
          submitDate: Timestamp.now(),
        },
      };

      // Add dossier to batch
      batch.set(dossierRef, dossierData);

      // Update user's private data
      const userDataRef = doc(db, `users/${currentUser.uid}/private/data`);
      batch.update(userDataRef, {
        onGoingDossiers: arrayUnion(dossierRef.id),
      });

      // Commit the batch
      await batch.commit();
      console.log('Dossier created successfully with ID:', dossierRef.id);

      checkout(dossierRef.id);
    } catch (error) {
      console.error('Error creating dossier: ', error);
      globalActions.setSnackbarMessage({ message: `${t('unexpected_error')} ${error}`, severity: 'error' });
      setPageStatus('initial');
    }
  };

  const handleStripeCheckout = async (dossierId: string) => {
    logEvent(analytics, 'clicked_checkout_session', {
      appName: 'Autonoom Web',
    });
    try {
      const result = await checkoutSession({
        success_url: `${window.location.origin}/app/dossiers/creation-vente`,
        cancel_url: `${window.location.origin}/app/dossiers/creation-vente`,
        fbu: currentUser.uid,
        did: dossierId,
      }) as StripeCheckoutResponse;
      window.location.href = result.data.url;
    } catch (error) {
      globalActions.setSnackbarMessage({ message: `${t('unexpected_error')} ${error}`, severity: 'error' });
    } finally {
      setPageStatus('done');
    }
  };

  const handleDevCheckout = async (dossierId: string) => {
    try {
      await new Promise((resolve) => {
        setTimeout(resolve, 1000);
      });
      // eslint-disable-next-line max-len
      window.location.href = `${window.location.origin}/app/dossiers/creation-vente?success=true&dossierId=${dossierId}`;
    } catch (error) {
      globalActions.setSnackbarMessage({ message: `${t('unexpected_error')} ${error}`, severity: 'error' });
    } finally {
      setPageStatus('done');
    }
  };

  // const checkout = process.env.NODE_ENV === 'development' ? handleDevCheckout : handleStripeCheckout;
  const checkout = handleStripeCheckout;

  const handleFindDossier = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSharingCode('');
  };

  const handleCodeSubmit = () => {
    if (sharingCode) {
      navigate(`/app/accepter-partage-dossier?sharingId=${sharingCode}`);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 3,
        width: '100%',
        maxWidth: 1200,
        mx: 'auto',
        p: { xs: 3, md: 6 },
      }}
    >
      <Box sx={{ textAlign: 'center', mb: 4 }}>
        <Typography variant="h5" sx={{ mb: 4 }}>
          {`Les formulaires que vous serez amener à remplir sur Autonoom vous permettrons de générer 
          un contrat de vente pour tout véhicule motorisé immatriculé en France.`}
        </Typography>
      </Box>

      <Box sx={{ width: '100%', maxWidth: 800, mb: 6 }}>
        <Typography variant="h6" gutterBottom sx={{ mb: 3 }}>
          {t('trust_features_title', 'Pourquoi choisir Autonoom ?')}
        </Typography>
        <Box sx={{ display: 'grid', gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' }, gap: 1 }}>
          {[
            {
              title: t('trust_feature_1_title', 'Sécurisé'),
              desc: t('trust_feature_1_desc', 'Protection et sécurité des données garanties'),
            },
            {
              title: t('trust_feature_2_title', 'Simple'),
              desc: t('trust_feature_2_desc', 'Interface intuitive à chaque étape'),
            },
            {
              title: t('trust_feature_3_title', 'Rapide'),
              desc: t('trust_feature_3_desc', 'Création en quelques minutes'),
            },
            {
              title: t('trust_feature_4_title', 'Conforme'),
              desc: t('trust_feature_4_desc', 'Documents conformes à la loi'),
            },
          ].map((feature) => (
            <Box
              key={feature.title}
              sx={{ p: 3, bgcolor: 'background.paper', borderRadius: 2 }}
            >
              <Typography variant="h6" gutterBottom>
                {feature.title}
              </Typography>
              <Typography variant="body1" color="text.secondary">
                {feature.desc}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>

      <Box sx={{ width: '100%', maxWidth: 600 }}>
        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" align="center" sx={{ mb: 3 }}>
            {t('newDossier.title', 'Créer un nouveau dossier')}
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <TextField
                label={t('newDossier.acquirerEmail', 'Email de l\'acquéreur')}
                name="acquirerEmail"
                inputRef={register('acquirerEmail', {
                  required: t('newDossier.emailRequired', 'Email requis'),
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: t('newDossier.invalidEmail', 'Email invalide'),
                  },
                }).ref}
                onChange={register('acquirerEmail').onChange}
                onBlur={register('acquirerEmail').onBlur}
                error={!!errors.acquirerEmail}
                helperText={errors.acquirerEmail?.message}
                fullWidth
              />

              <TextField
                label={t('newDossier.vehicleBrand', 'Marque du véhicule')}
                name="vehicleBrand"
                inputRef={register('vehicleBrand', {
                  required: t('newDossier.brandRequired', 'Marque requise'),
                }).ref}
                onChange={register('vehicleBrand').onChange}
                onBlur={register('vehicleBrand').onBlur}
                error={!!errors.vehicleBrand}
                helperText={errors.vehicleBrand?.message}
                fullWidth
              />

              <TextField
                label={t('newDossier.vehicleModel', 'Modèle du véhicule')}
                name="vehicleModel"
                inputRef={register('vehicleModel', {
                  required: t('newDossier.modelRequired', 'Modèle requis'),
                }).ref}
                onChange={register('vehicleModel').onChange}
                onBlur={register('vehicleModel').onBlur}
                error={!!errors.vehicleModel}
                helperText={errors.vehicleModel?.message}
                fullWidth
              />

              <LoadingButton
                type="submit"
                variant="contained"
                size="large"
                loading={pageStatus === 'loading'}
                startIcon={<ShoppingCartIcon />}
                fullWidth
                sx={{
                  mt: 3,
                  py: 2,
                  fontSize: '1.1rem',
                  fontWeight: 'bold',
                  backgroundColor: 'primary.dark',
                  '&:hover': {
                    backgroundColor: 'primary.main',
                  },
                }}
              >
                {t('newDossier.submit')}
              </LoadingButton>
              <Typography variant="body1" color="text" align="center" sx={{ fontWeight: 'bold', mb: 2 }}>
                {t('newDossier.price_info')}
              </Typography>
            </Box>
          </form>
        </Box>

        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="body1" sx={{ mb: 2 }}>
            {t('or_find_existing', 'Ou retrouvez un dossier existant')}
          </Typography>
          <Button
            variant="outlined"
            size="large"
            startIcon={<SearchIcon />}
            onClick={handleFindDossier}
            fullWidth
          >
            {t('find_dossier')}
          </Button>
        </Box>
      </Box>

      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>{t('share.enter_sharing_code')}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label={t('share.share_code_placeholder')}
            type="text"
            fullWidth
            variant="outlined"
            value={sharingCode}
            onChange={(e) => setSharingCode(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            {t('cancel', 'Annuler')}
          </Button>
          <Button
            onClick={handleCodeSubmit}
            color="primary"
            variant="contained"
            disabled={!sharingCode}
          >
            {t('submit', 'Valider')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
