import {
  Box, Button, Collapse, Paper, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ReadonlyView from './ReadonlyView';

export default function MyCollapse({
  setExpand, expanded, expandIcon, form, title, borderColor, theRef,
  formType, modifyCondition = () => false, handleModify = () => {}, dossier, additionalForm,
}) {
  const { t } = useTranslation();

  return (
    <Paper
      ref={theRef}
      sx={{
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        justifyContent: 'center',
        alignItems: 'center',
        border: 2,
        borderColor,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: 1,
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={() => setExpand(!expanded)}
      >
        <Typography variant="h6">
          {title}
        </Typography>
        <Button
          size="small"
          color="inherit"
          variant="outlined"
          endIcon={expandIcon(expanded)}
          sx={{ fontSize: 10, width: 102 }}
        >
          {expanded ? t('collapse') : t('expand')}
        </Button>

      </Box>

      <Collapse
        in={expanded}
      >
        <Box sx={{
          display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center',
        }}
        >
          <ReadonlyView
            formType={formType}
            initialFormState={form.values}
            dossier={dossier}
            additionalForm={additionalForm}
          />
          {modifyCondition() && (
            <Button size="small" variant="outlined" onClick={handleModify}>
              {t('modify')}
            </Button>
          )}
          <Button
            size="small"
            color="inherit"
            variant="outlined"
            endIcon={expandIcon(expanded)}
            sx={{ fontSize: 10, width: 102, alignSelf: 'flex-end' }}
            onClick={() => setExpand(!expanded)}
          >
            {expanded ? t('collapse') : t('expand')}
          </Button>
        </Box>
      </Collapse>
    </Paper>
  );
}
