import React, { useMemo } from 'react';
import { Typography, Box } from '@mui/material';

export default function HighlightedText({
  text,
  textVariantColor = 'white',
  highlights = [],
  color = '#4fde9c',
  variant = 'body1',
  sx = {},
}) {
  const parts = useMemo(() => text.split(' ').map((part, idx) => ({
    text: part,
    key: `${part}-${idx}-${Math.random().toString(36).substr(2, 9)}`,
    isHighlighted: highlights.includes(part),
  })), [text, highlights]);

  return (
    <Typography variant={variant} sx={sx}>
      {parts.map((part, index) => (
        <React.Fragment key={part.key}>
          {part.isHighlighted ? (
            <Box
              component="span"
              sx={{
                backgroundColor: color,
                px: 1,
                py: 0.5,
                borderRadius: 1,
                whiteSpace: 'pre-wrap',
                color: textVariantColor,
              }}
            >
              {part.text}
            </Box>
          ) : part.text}
          {index < parts.length - 1 ? ' ' : ''}
        </React.Fragment>
      ))}
    </Typography>
  );
}
