import React, { useEffect } from 'react';
import { useAuth } from 'firebaseHooks/AuthContext';
import { useSignIn } from 'useSignIn/SingInContext';
import LoadingPage from './LoadingPage';

interface ProtectedRouteProps {
  element: React.ReactElement;
}

export default function ProtectedRoute({ element }: ProtectedRouteProps): JSX.Element {
  const { currentUser, initializing } = useAuth();
  const { openSignin } = useSignIn();

  useEffect(() => {
    if (!initializing && !currentUser) {
      openSignin();
    }
  }, [currentUser, initializing, openSignin]);

  if (initializing || !currentUser) {
    return <LoadingPage />;
  }

  return element;
}
