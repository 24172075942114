import React from 'react';
import {
  Box,
  CssBaseline,
  Button,
} from '@mui/material';
import {
  createTheme, styled, StyledEngineProvider, ThemeProvider,
} from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import AutonoomPresentation from 'components/AutonoomPresentation';
import SSRFooter from 'components/SSRFooter';

const theme = createTheme(
  {
    palette: {
      primary: {
        main: '#14c7bf',
        contrastText: 'white',
      },
      secondary: {
        main: '#4fde9c',
        contrastText: 'white',
      },
      info: {
        main: '#002b34',
      },
      text: {
        primary: '#09524e',
      },
      gold: {
        main: '#FFD700',
        contrastText: 'white',
      },
      darkgray: {
        main: '#0000008a',
        contrastText: 'white',
      },
      mediumgray: {
        main: '#8585858a',
        contrastText: 'white',
      },
      gray: {
        main: '#d8d8d8',
        contrastText: 'white',
      },
      lightGray: {
        main: '#f5f5f7',
        contrastText: 'black',
      },
      link: {
        main: '#109993',
        contrastText: 'white',
      },
    },
  },
);

const GlobalStyle = styled('div')(({ theme: t }) => ({
  backgroundColor: t.palette.background.default,
  width: '100%',
  minHeight: '100vh',
  position: 'absolute',
  top: 0,
  left: 0,
}));

function LandingPage() {
  const navigate = useNavigate();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <GlobalStyle>
          <>
            <CssBaseline />
            <Box
              component="main"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100vw',
                alignItems: 'center',
                pt: { xs: 10, md: 12 },
                position: 'relative',
              }}
            >
              <Button
                variant="outlined"
                size="small"
                onClick={() => navigate('/app')}
                color="primary"
                sx={{
                  position: 'absolute',
                  top: { xs: 16, md: 24 },
                  right: { xs: 16, md: 24 },
                }}
              >
                Se connecter
              </Button>
              <Box sx={{
                minHeight: '75vh',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
              >
                <AutonoomPresentation />
              </Box>
              <SSRFooter />
            </Box>
          </>
        </GlobalStyle>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default LandingPage;
