import React from 'react';
import {
  Box, TextField, ToggleButton, ToggleButtonGroup, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import InputFileUpload from 'components/customInputs/InputFileUpload';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default function Vehicle({ formik, isReadOnly }) {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        mt: 3,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <Typography variant="h6">{`${t('acquirerForm.vehicle.title')} : `}</Typography>
      <Box sx={{
        display: 'flex',
        gap: 3,
        alignItems: 'center',
      }}
      >
        <Typography>{`${t('acquirerForm.vehicle.vehicleSeen')}`}</Typography>
        <ToggleButtonGroup
          disabled={isReadOnly}
          color="primary"
          value={formik.values.vehicle.vehicleSeen}
          exclusive
          onChange={(event, newAlignment) => {
            formik.setFieldValue('vehicle.vehicleSeen', newAlignment);
          }}
          aria-label="acquirerForm.vehicle.vehicleSeen"
          size="small"
        >
          <ToggleButton value>{t('yes')}</ToggleButton>
          <ToggleButton value={false}>{t('no')}</ToggleButton>
        </ToggleButtonGroup>
      </Box>

      {formik.values.vehicle.vehicleSeen && (
        <SeenVehicle formik={formik} isReadOnly={isReadOnly} />
      )}

    </Box>
  );
}

function SeenVehicle({ formik, isReadOnly }) {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        mt: 3,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          disabled={isReadOnly}
          sx={{ width: '100%' }}
          label={t('acquirerForm.vehicle.seenVehicle.date')}
          format="DD/MM/YYYY"
          value={formik.values.vehicle.seenVehicle.date}
          onChange={(date) => {
            formik.setFieldValue('vehicle.seenVehicle.date', date);
          }}
          slotProps={{
            textField: {
              name: 'vehicle.seenVehicle.date',
              error: Boolean(formik.touched.vehicle?.seenVehicle?.date
                    && formik.errors.vehicle?.seenVehicle?.date),
              helperText: formik.touched.vehicle?.seenVehicle?.date
                && formik.errors.vehicle?.seenVehicle?.date,
              onBlur: () => formik.setFieldTouched('vehicle.seenVehicle.date', true),
            },
          }}
        />
      </LocalizationProvider>

      <Box sx={{
        display: 'flex',
        gap: 3,
        alignItems: 'center',
      }}
      >
        <Typography>{`${t('acquirerForm.vehicle.seenVehicle.testDrive')}`}</Typography>
        <ToggleButtonGroup
          disabled={isReadOnly}
          color="primary"
          value={formik.values.vehicle.seenVehicle.testDrive}
          exclusive
          onChange={(event, newAlignment) => {
            formik.setFieldValue('vehicle.seenVehicle.testDrive', newAlignment);
          }}
          aria-label="acquirerForm.vehicle.seenVehicle.testDrive"
          size="small"
        >
          <ToggleButton value>{t('yes')}</ToggleButton>
          <ToggleButton value={false}>{t('no')}</ToggleButton>
        </ToggleButtonGroup>
      </Box>

      {formik.values.vehicle.seenVehicle.testDrive && (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            disabled={isReadOnly}
            sx={{ width: '100%' }}
            label={t('acquirerForm.vehicle.seenVehicle.testDriveDate')}
            format="DD/MM/YYYY"
            value={formik.values.vehicle.seenVehicle.testDriveDate}
            onChange={(testDriveDate) => {
              formik.setFieldValue('vehicle.seenVehicle.testDriveDate', testDriveDate);
            }}
            slotProps={{
              textField: {
                name: 'vehicle.seenVehicle.testDriveDate',
                error: Boolean(formik.touched.vehicle?.seenVehicle?.testDriveDate
                            && formik.errors.vehicle?.seenVehicle?.testDriveDate),
                helperText: formik.touched.vehicle?.seenVehicle?.testDriveDate
                        && formik.errors.vehicle?.seenVehicle?.testDriveDate,
                onBlur: () => formik.setFieldTouched('vehicle.seenVehicle.testDriveDate', true),
              },
            }}
          />
        </LocalizationProvider>
      )}

      <Box sx={{
        display: 'flex',
        gap: 3,
        alignItems: 'center',
      }}
      >
        <Typography>{`${t('acquirerForm.vehicle.seenVehicle.vehicleConformToDescriptionAndPhotographs')}`}</Typography>
        <ToggleButtonGroup
          disabled={isReadOnly}
          color="primary"
          value={formik.values.vehicle.seenVehicle.vehicleConformToDescriptionAndPhotographs}
          exclusive
          onChange={(event, newAlignment) => {
            formik.setFieldValue(
              'vehicle.seenVehicle.vehicleConformToDescriptionAndPhotographs',
              newAlignment,
            );
          }}
          aria-label="acquirerForm.vehicle.seenVehicle.vehicleConformToDescriptionAndPhotographs"
          size="small"
        >
          <ToggleButton value>{t('yes')}</ToggleButton>
          <ToggleButton value={false}>{t('no')}</ToggleButton>
        </ToggleButtonGroup>
      </Box>

      {!formik.values.vehicle.seenVehicle.vehicleConformToDescriptionAndPhotographs && (
        <TextField
          disabled={isReadOnly}
          label={t('acquirerForm.vehicle.seenVehicle.vehicleConformToDescriptionAndPhotographsMessage')}
          name="vehicle.seenVehicle.vehicleConformToDescriptionAndPhotographsMessage"
          multiline
          rows={4}
          value={formik.values.vehicle.seenVehicle.vehicleConformToDescriptionAndPhotographsMessage}
          onChange={formik.handleChange}
          error={formik.touched.vehicle?.seenVehicle?.vehicleConformToDescriptionAndPhotographsMessage
            && Boolean(formik.errors.vehicle?.seenVehicle?.vehicleConformToDescriptionAndPhotographsMessage)}
          helperText={formik.touched.vehicle?.seenVehicle?.vehicleConformToDescriptionAndPhotographsMessage
            && formik.errors.vehicle?.seenVehicle?.vehicleConformToDescriptionAndPhotographsMessage}
        />
      )}

      <TextField
        disabled={isReadOnly}
        label={t('acquirerForm.vehicle.seenVehicle.furtherComments')}
        name="vehicle.seenVehicle.furtherComments"
        multiline
        rows={4}
        value={formik.values.vehicle.seenVehicle.furtherComments}
        onChange={formik.handleChange}
      />

      <InputFileUpload
        isReadOnly={isReadOnly}
        fileBaseName={t('fileName.acquirerVehiclePhoto')}
        formik={formik}
        field="vehicle.seenVehicle.addedPhotos"
        label={`${t('acquirerForm.vehicle.seenVehicle.addedPhotos')} : `}
        accept="image/jpeg,image/png"
        maxFiles={5}
      />

    </Box>
  );
}
