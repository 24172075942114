import moment from 'moment';
import SharedSaleForm from 'components/vehicleSale/sellerForm/SharedSaleForm';
import SharedSellerSaleFormalitiesForm
  from 'components/vehicleSale/sellerSaleFormalitiesForm/SharedSellerSaleFormalitiesForm';
import SharedBuyerForm from 'components/vehicleSale/buyerForm/SharedBuyerForm';

export default function ReadonlyView({
  initialFormState, formType, dossier, additionalForm,
}) {
  const prepareFormValues = (values) => {
    if (Array.isArray(values)) {
      return values.map((item) => prepareFormValues(item));
    } if (typeof values === 'object' && values !== null) {
      // Check if object is a Firestore timestamp-like object
      if ('seconds' in values && (values.nanoseconds || values.nanoseconds === 0)) {
        // Convert to Date object; alternatively, you can use moment.unix(values.seconds)
        return moment.unix(values.seconds);
      }

      // If not a timestamp object, recursively process each property
      const deserializedValues = {};
      Object.keys(values).forEach((key) => {
        deserializedValues[key] = prepareFormValues(values[key]);
      });
      return deserializedValues;
    }
    return values;
  };
  if (formType === 'saleForm') {
    return (
      <SharedSaleForm
        mode="readonly"
        initialValues={prepareFormValues(initialFormState)}
        dossierId={dossier.id}
      />
    );
  } if (formType === 'formalitiesForm') {
    return (
      <SharedSellerSaleFormalitiesForm
        mode="readonly"
        dossier={dossier}
        initialValues={prepareFormValues(initialFormState)}
      />
    );
  }
  return (
    <SharedBuyerForm
      mode="readonly"
      dossier={dossier}
      initialValues={prepareFormValues(initialFormState)}
      sellerForm={additionalForm}
    />
  );
}
