import React from 'react';
import {
  Routes,
  Route,
  useNavigate,
  Navigate,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useGlobal from 'global-state/store';
import { getAnalytics } from 'firebase/analytics';
import {
  addDoc,
  collection,
} from 'firebase/firestore';
import Contact from 'components/Contact';
import * as Sentry from '@sentry/react';
import { useFirestore } from 'firebaseHooks/FirestoreContext';
import { useAuth } from 'firebaseHooks/AuthContext';
import NewDossier from 'app/seller/NewDossier';
import MyDossiersList from 'app/dossiers/MyDossiersList';
import DossierPage from 'app/dossiers/DossierPage';
import ContractPDFPage from 'app/dossiers/ContractPDFPage';
import ProtectedRoute from './ProtectedRoute.tsx';
import MyAccount from './account/MyAccount';
import AcceptSaleInvitation from './saleInvitation/AcceptVehicleSale';
import BuyerForm from './vehicleSale/buyerForm/BuyerForm';
import SellerSaleFormalitiesForm from './vehicleSale/sellerSaleFormalitiesForm/SellerSaleFormalitiesForm';
import Admin from './admin/Admin';
import QRCodes from './qrcodes/QRCodes';
import SaleForm from './vehicleSale/sellerForm/SaleForm';
import NotFound from './NotFound';

export default function AutoRoutes() {
  const [, globalActions] = useGlobal();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const analytics = getAnalytics();
  const db = useFirestore();
  const { currentUser: user } = useAuth();

  const sendEmail = async (values) => {
    const emailContent = `Email: 
      ${values.email}

      Nom: 
      ${values.nameAndCompany}

      Message: 
      ${values.message}`;

    await addDoc(
      collection(db, 'mail'),
      {
        to: 'contact@autonoom.fr',
        message: {
          subject: `[Autonoom, Contact] ${values.nameAndCompany}`,
          text: emailContent,
        },
      },
    );
  };

  const contactElement = (
    <Contact
      t={t}
      navigate={navigate}
      globalActions={globalActions}
      analytics={analytics}
      sendEmail={sendEmail}
      currentUser={user}
    />
  );

  // All routes configuration
  const routes = [
    { path: '/', element: <Navigate to="/app/dossiers" replace /> },
    { path: '/dossiers/creation-vente', element: <NewDossier />, protected: true },
    { path: '/dossiers', element: <MyDossiersList />, protected: true },
    { path: '/dossiers/:dossierId', element: <DossierPage />, protected: true },
    {
      path: '/dossiers/:dossierId/formulaire-vendeur',
      element: <SaleForm />,
      protected: true,
    },
    {
      path: '/dossiers/:dossierId/formulaire-acquereur',
      element: <BuyerForm />,
      protected: true,
    },
    {
      path: '/dossiers/:dossierId/formulaire-formalites-vente',
      element: <SellerSaleFormalitiesForm />,
      protected: true,
    },
    {
      path: '/dossiers/:dossierId/contrat-pdf',
      element: <ContractPDFPage />,
      protected: true,
    },
    { path: '/compte', element: <MyAccount />, protected: true },
    { path: '/admin', element: <Admin />, protected: true },
    { path: 'contact', element: contactElement },
    { path: '/accepter-partage-dossier', element: <AcceptSaleInvitation /> },
    { path: '/qrcodes/:qrCodeId', element: <QRCodes /> },
    { path: '*', element: <NotFound /> },
  ];

  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

  return (
    <SentryRoutes>
      {routes.map(({ path, element, protected: isProtected }) => (
        <Route
          key={path}
          path={path}
          element={isProtected ? (
            <ProtectedRoute element={element} />
          ) : element}
        />
      ))}
    </SentryRoutes>
  );
}
