import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { getStorage, ref, getBlob } from 'firebase/storage';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import Alert from '@mui/material/Alert';
import { CircularProgress } from '@mui/material';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export default function StoragePDFViewer({ path, loadedCallback = () => {}, actionButtons = [] }) {
  const { t } = useTranslation();
  const storage = getStorage();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [blobUrl, setBlobUrl] = useState();
  const [blob, setBlob] = useState();
  const [fileError, setFileError] = useState('');
  const [loadingState, setLoadingState] = useState('initial');
  const [scale, setScale] = useState(1);
  const [rescaled, setRescaled] = useState(false);
  const [pdfDimensions, setPdfDimensions] = useState();

  useEffect(() => {
    const handleResize = () => {
      if (loadingState === 'done') {
        updateScale(pdfDimensions);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [loadingState, pdfDimensions]);

  useEffect(() => {
    async function loadPdf() {
      if (!path) {
        setFileError(t('storage.invalid_path'));
        setLoadingState('done');
        return;
      }

      try {
        setLoadingState('loading');
        const fileRef = ref(storage, path);
        const pdfBlob = await getBlob(fileRef);
        setBlob(pdfBlob);
        const newBlobUrl = URL.createObjectURL(pdfBlob);
        setBlobUrl(newBlobUrl);
        setFileError('');
        loadedCallback(newBlobUrl, pdfBlob);
        setLoadingState('done');
      } catch (error) {
        console.error('PDF load error:', error);
        setLoadingState('done');
        if (error.code === 'storage/object-not-found') {
          setFileError(t('storage.file_not_found'));
        } else if (error.code === 'storage/invalid-root-operation') {
          setFileError(t('storage.invalid_path'));
        } else {
          setFileError(error.message);
        }
      }
    }

    if (loadingState === 'initial') {
      loadPdf();
    }

    return () => {
      if (blobUrl) {
        URL.revokeObjectURL(blobUrl);
      }
    };
  }, [blobUrl, loadedCallback, loadingState, path, storage, t]);

  const onDocumentLoadSuccess = (obj) => {
    setNumPages(obj.numPages);
  };

  const updateScale = ({ width, height }) => {
    const pageAspectRatio = width / height;
    const windowAspectRatio = window.innerWidth / window.innerHeight;
    setRescaled(true);
    if (windowAspectRatio > pageAspectRatio) {
      const newScale = window.innerHeight * 75 / 100 / height;
      setScale(newScale);
    } else {
      const newScale = window.innerWidth * 75 / 100 / width;
      setScale(newScale);
    }
  };

  const onPageLoadSuccess = ({ width, height }) => {
    if (!rescaled) {
      setPdfDimensions({ width, height });
      updateScale({ width, height });
    }
  };

  if (loadingState === 'loading') {
    return (
      <Box sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (fileError) {
    return <Alert severity="error">{fileError}</Alert>;
  }

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      mb: 3,
    }}
    >
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
      }}
      >
        <Box sx={{ mb: 3, border: 1, borderColor: 'text.primary' }}>
          <Document
            file={blobUrl}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={(error) => console.error('PDF document load error:', error)}
          >
            <Page
              pageNumber={pageNumber}
              renderTextLayer={false}
              scale={scale}
              onLoadSuccess={onPageLoadSuccess}
            />
          </Document>
        </Box>
        {numPages > 1 && (
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            mb: 3,
          }}
          >
            <Button
              disabled={pageNumber === 1}
              size="small"
              variant="contained"
              color="primary"
              onClick={() => setPageNumber((prevNumber) => prevNumber - 1)}
            >
              {t('navigation.previous_page')}
            </Button>
            Page
            {' '}
            {pageNumber}
            {' '}
            /
            {' '}
            {numPages}
            <Button
              disabled={pageNumber === numPages}
              size="small"
              variant="contained"
              color="primary"
              onClick={() => setPageNumber((prevNumber) => prevNumber + 1)}
            >
              {t('navigation.next_page')}
            </Button>
          </Box>
        )}
        <Box sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
        }}
        >
          {actionButtons.map((action) => (
            <Button
              key={action.name}
              size="small"
              variant="contained"
              color="primary"
              onClick={() => action.action(blobUrl, blob)}
              endIcon={action.icon}
            >
              {action.name}
            </Button>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
