import React from 'react';
import {
  Box,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import AgreeCheckMark from 'components/customInputs/AgreeCheckMark';
import { timeStampFrom } from 'components/utils';

export default function SaleDetails({
  formik, isReadOnly, ownerFormValues, mode, bothPartiesPresent,
}) {
  const { t } = useTranslation();

  const {
    deliveryDateRange, paymentMethod, deliveryLocation, additionalInfo, price,
  } = ownerFormValues.vehicleSaleDetails;

  const deliveryLocationText = Object.values(deliveryLocation).join('\n');

  const deliveryText = `${t('delivery_between_the_and_the', {
    start: timeStampFrom(deliveryDateRange.start).toDate().toLocaleDateString(),
    end: timeStampFrom(deliveryDateRange.end).toDate().toLocaleDateString(),
  })} \n
  ${t('at_adress', { address: deliveryLocationText })}
  ${t('additional_info', { additionalInfo: additionalInfo || t('none_e') })}`;

  const paiementText = t('payment_with', { amount: price, paymentMethod });

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      gap: 2,
    }}
    >
      <AgreeCheckMark
        isReadOnly={isReadOnly}
        formik={formik}
        field="saleDetails.confirmKnowledgeofAlltransmittedInfo"
        label={t('acquirerForm.saleDetails.confirmKnowledgeofAlltransmittedInfo')}
        extraText={t('acquirerForm.saleDetails.confirmKnowledgeofAlltransmittedInfoExtra')}
        openAtStart={mode === 'create' && !bothPartiesPresent}
      />
      <Typography sx={{ textAlign: 'left', mt: 3 }}>{deliveryText}</Typography>
      <AgreeCheckMark
        isReadOnly={isReadOnly}
        formik={formik}
        field="saleDetails.confirmDeliveryDate"
        label={t('acquirerForm.saleDetails.confirmDeliveryDate')}
        extraText={deliveryText}

      />
      <Typography sx={{ textAlign: 'left', mt: 3 }}>{paiementText}</Typography>
      <AgreeCheckMark
        isReadOnly={isReadOnly}
        formik={formik}
        field="saleDetails.confirmPaymentMethod"
        label={t('acquirerForm.saleDetails.confirmPaymentMethod')}
        extraText={paiementText}
      />

    </Box>
  );
}
