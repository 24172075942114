import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';

export default function NotFound() {
  const navigate = useNavigate();

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '100vh',
      gap: 3,
    }}
    >
      <Typography
        variant="h4"
        sx={{
          fontWeight: 'bold',
        }}
      >
        Page non trouvée
      </Typography>
      <Button
        variant="contained"
        sx={{
          backgroundColor: '#14c7bf',
          '&:hover': {
            backgroundColor: '#002b34',
          },
        }}
        onClick={() => navigate('/')}
      >
        Retour à l&apos;accueil
      </Button>
    </Box>
  );
}
