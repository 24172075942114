import { extractFilesFromFormikValues, processAndReplaceFiles, uploadWithTimeout } from 'components/utils';
import {
  collection, doc, writeBatch,
} from 'firebase/firestore';
import {
  ref as storageRef,
} from 'firebase/storage';
import { logEvent } from 'firebase/analytics';
import { emailTemplate } from 'components/emails/EmailTemplate';
import { nanoid } from 'nanoid';

const uploadFiles = async (t, storage, files, basePath) => {
  const uploadPromises = files.map(async (fileData, index) => {
    const fileRef = storageRef(storage, `${basePath}/${fileData.path}/${fileData.file.name}`);
    console.log(`Uploading file ${index + 1}/${files.length}: ${fileData.file.name}`);

    await uploadWithTimeout(t, fileRef, fileData.file);

    console.log(`Finished uploading file ${index + 1}/${files.length}: ${fileData.file.name}`);
  });
  await Promise.all(uploadPromises);
};

export default async function submitForm(
  {
    mode,
    formik,
    storage,
    firestore,
    setPageStatus,
    user,
    buyerEmail,
    t,
    navigate,
    analytics,
    globalActions,
    clearFormStorage,
    dossierId,
    successState,
  },
) {
  setPageStatus('loading');
  try {
    const allFiles = [];

    extractFilesFromFormikValues(formik.values, allFiles);

    const sharingId = nanoid(10);
    const sellerFormDocPath = `dossiers/${dossierId}/forms/sellerForm`;

    let valuesToStore = await processAndReplaceFiles(formik.values, allFiles, sellerFormDocPath);
    valuesToStore = { ...valuesToStore };

    const prettyPrinted = JSON.stringify(valuesToStore, null, 2);
    console.log('FINAL VALUES: ', prettyPrinted);

    const batch = writeBatch(firestore);
    const dossierDocRef = doc(firestore, 'dossiers', dossierId);
    const sellerFormRef = doc(dossierDocRef, 'forms', 'sellerForm');
    let dossierData;
    let sellerFormData;

    if (mode === 'create') {
      dossierData = {
        state: successState,
        sharingId,
        acquirerEmail: buyerEmail,
      };
      sellerFormData = {
        submitDate: new Date(),
        values: valuesToStore,
      };
      batch.set(sellerFormRef, sellerFormData);
      batch.update(dossierDocRef, dossierData);
    } else {
      sellerFormData = {
        updateDate: new Date(),
        values: valuesToStore,
      };
      batch.update(sellerFormRef, sellerFormData);
    }

    console.log('uploading files to: ', sellerFormRef.path);

    await uploadFiles(t, storage, allFiles, sellerFormDocPath);

    console.log('files uploaded');

    const mailDocRef = doc(collection(firestore, 'mail'));

    const baseUrl = window.location.origin;
    const path = '/app/accepter-partage-dossier';
    const queryParams = `?sharingId=${sharingId}`;
    const sharingLink = `${baseUrl}${path}${queryParams}`;

    const messageHtml1 = emailTemplate(` <p>${t('sendsaleForm.sale_invite_email_content', {
      sellerName: valuesToStore.seller.sellerType === 'individual'
        ? valuesToStore.seller.individual.firstName + valuesToStore.seller.individual.lastName
        : valuesToStore.seller.entity.companyName,
      vehicleName: valuesToStore.vehicle.model,
      link: ` <p>
        <a href="${sharingLink}"
           style="
             display: inline-block;
             padding: 10px 20px;
             background-color: #14c7bf;
             color: white;
             text-decoration: none;
             border-radius: 5px;
             font-weight: bold;
           ">
            Accepter de compléter le contrat
        </a>
      </p>`,
      linkText: sharingLink,
    })}</p>`);

    if (mode === 'create') {
      batch.set(mailDocRef, {
        to: buyerEmail,
        message: {
          subject: t('sendsaleForm.sale_invite_email_title'),
          html: messageHtml1,
        },
      });

      const contractShareDocRef = doc(firestore, 'dossierShares', sharingId);
      batch.set(contractShareDocRef, {
        dossierReference: dossierDocRef.path,
        name: valuesToStore.vehicle.model,
        from: valuesToStore.seller.sellerType === 'individual'
          ? valuesToStore.seller.individual.firstName + valuesToStore.seller.individual.lastName
          : valuesToStore.seller.entity.companyName,
      });
    } else {
      const messageHtml2 = emailTemplate(` <p>${t('sendsaleForm.sale_update_email_content', {
        sellerName: valuesToStore.seller.sellerType === 'individual'
          ? valuesToStore.seller.individual.firstName + valuesToStore.seller.individual.lastName
          : valuesToStore.seller.entity.companyName,
        vehicleName: valuesToStore.vehicle.model,
        link: ` <p>
          <a href="${baseUrl}/app/dossiers"
             style="
               display: inline-block;
               padding: 10px 20px;
               background-color: #14c7bf;
               color: white;
               text-decoration: none;
               border-radius: 5px;
               font-weight: bold;
             ">
              Mes contrats
          </a>
        </p>`,
        linkText: `${baseUrl}/app/dossiers`,
      })}</p>`);

      batch.set(mailDocRef, {
        to: buyerEmail,
        message: {
          subject: t('sendsaleForm.sale_update_email_title'),
          html: messageHtml2,
        },
      });
    }

    await batch.commit();
    clearFormStorage();

    if (mode === 'create') {
      globalActions.setSnackbarMessage({ message: t('success'), severity: 'success', displayDuration: 2000 });
      navigate('/app/dossiers', { replace: true });
    } else {
      globalActions.setSnackbarMessage({ message: t('success'), severity: 'success', displayDuration: 2000 });
      navigate(-1);
    }

    logEvent(analytics, mode === 'create' ? 'seller-form-created' : 'seller-form-updated', {
      appName: 'Autonoom Web',
      user: user.uid,
    });
  } catch (err) {
    globalActions.setSnackbarMessage({ message: `${t('unexpected_error')} : ${err}`, severity: 'error' });
    throw err;
  } finally {
    setPageStatus('done');
  }
}
