import React, { useState, useEffect } from 'react';
import {
  doc, getDoc,
} from 'firebase/firestore';
import {
  Box, Button, CircularProgress, List, ListItem, Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useGlobal from 'global-state/store';
import AddIcon from '@mui/icons-material/Add';
import { useAuth } from 'firebaseHooks/AuthContext';
import { useFirestore } from 'firebaseHooks/FirestoreContext';
import DossierListItem from './DossierListItem';

function MyDossiersList() {
  const { currentUser: user } = useAuth();

  const [dossiers, setDossiers] = useState([]);
  const [doneDossiers, setDoneDossiers] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const firestore = useFirestore();
  const [, globalActions] = useGlobal();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchContracts = async () => {
      try {
        setLoading(true);

        // Fetch user's private data
        const privateDataDoc = await getDoc(doc(firestore, `/users/${user.uid}/private/data`));
        if (!privateDataDoc.exists()) {
          throw new Error(`Document not found: ${privateDataDoc.ref.path}`);
        }
        const userDossiersIds = privateDataDoc.data().onGoingDossiers;

        // Fetch each contract
        const dossiersPromises = userDossiersIds.map(
          (dossierId) => getDoc(doc(firestore, `/dossiers/${dossierId}`)),
        );
        const dossiersDocs = await Promise.all(dossiersPromises);

        const dossiersData = dossiersDocs.map((document) => ({ id: document.id, ...document.data() }));

        const doneDossiersData = dossiersData.filter(
          (dossier) => dossier.state === 'completed',
        );
        const ongoingDossiersData = dossiersData.filter(
          (dossier) => dossier.state !== 'completed',
        );

        setDossiers(ongoingDossiersData);
        setDoneDossiers(doneDossiersData);
      } catch (err) {
        globalActions.setSnackbarMessage({ message: `${t('unexpected_error')} ${err.message}`, severity: 'error' });
        throw err;
      } finally {
        setLoading(false);
      }
    };

    fetchContracts();
  }, [firestore, globalActions, t, user.uid]);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 1,
      backgroundColor: 'lightGray.main',
      width: '100vw',
      minHeight: '80vh',
      mt: { xs: -10, md: -12 },
      pt: { xs: 10, md: 12 },
      px: 1,
      pb: 5,
    }}
    >
      {dossiers.length > 0 && (
      <>
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{t('my_dossiers')}</Typography>

        <List sx={{ width: '100%', maxWidth: 1000 }}>
          {dossiers.map((item, index) => (
            <ListItem
              key={item.id}
              sx={{
                width: '100%',
              }}
            >
              <DossierListItem
                itemData={item}
                onClick={() => {
                  navigate(`${item.id}`, {
                    state: {
                      dossier: item,
                    },
                  });
                }}
              />
            </ListItem>
          ))}
        </List>
      </>
      )}

      {doneDossiers.length > 0 && (
      <>
        <Typography variant="h6" sx={{ mt: 6, fontWeight: 'bold' }}>{t('my_finished_dossiers')}</Typography>
        <List sx={{ width: '100%', maxWidth: 1000 }}>
          {doneDossiers.map((item, index) => (
            <ListItem
              key={item.id}
              sx={{
                width: '100%',
              }}
            >
              <DossierListItem
                itemData={item}
                onClick={() => {
                  navigate(`${item.id}`, {
                    state: {
                      dossier: item,
                    },
                  });
                }}
              />
            </ListItem>
          ))}
        </List>
      </>
      )}

      <Button
        variant="outlined"
        sx={{ fontWeight: 'bold' }}
        endIcon={<AddIcon />}
        onClick={() => navigate('/app/dossiers/creation-vente')}
      >
        {t('start_dossier')}
      </Button>
    </Box>
  );
}

export default MyDossiersList;
