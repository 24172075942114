import React from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { initialValues as defaultInitialValues } from './initialValues';
import { usePersistedFormikState } from './formikPersistence';
import SharedSaleForm from './SharedSaleForm';

function CreateSaleForm({
  initialFormState, successState, acquirerEmail, dossierId,
}) {
  const [formState, setFormState] = usePersistedFormikState(initialFormState, 'saleForm');

  const handleFormStateChange = (newFormState) => {
    setFormState(newFormState);
  };

  return (
    <SharedSaleForm
      mode="create"
      initialValues={formState}
      onFormStateChange={handleFormStateChange}
      successState={successState}
      dossierId={dossierId}
      acquirerEmail={acquirerEmail}
    />
  );
}

function EditSaleForm({
  initialFormState, dossierId, successState, acquirerEmail,
}) {
  const prepareFormValues = (values) => {
    if (Array.isArray(values)) {
      return values.map((item) => prepareFormValues(item));
    } if (typeof values === 'object' && values !== null) {
      // Check if object is a Firestore timestamp-like object
      if ('seconds' in values && (values.nanoseconds || values.nanoseconds === 0)) {
        // Convert to Date object; alternatively, you can use moment.unix(values.seconds)
        return moment.unix(values.seconds);
      }

      // If not a timestamp object, recursively process each property
      const deserializedValues = {};
      Object.keys(values).forEach((key) => {
        deserializedValues[key] = prepareFormValues(values[key]);
      });
      return deserializedValues;
    }
    return values;
  };
  return (
    <SharedSaleForm
      mode="edit"
      initialValues={prepareFormValues(initialFormState)}
      dossierId={dossierId}
      successState={successState}
      acquirerEmail={acquirerEmail}
    />
  );
}

export default function SaleForm() {
  const location = useLocation();
  const {
    mode = 'create', initialValues = defaultInitialValues, dossierId, successState, acquirerEmail = '',
  } = location.state || {};

  if (mode === 'create') {
    return (
      <CreateSaleForm
        initialFormState={initialValues}
        dossierId={dossierId}
        acquirerEmail={acquirerEmail}
        successState={successState}
      />
    );
  }
  return (
    <EditSaleForm
      initialFormState={initialValues}
      dossierId={dossierId}
      successState={successState}
      acquirerEmail={acquirerEmail}
    />
  );
}
