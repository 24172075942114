// FirestoreContext.js
import React, { createContext, useContext } from 'react';
import {
  initializeFirestore, persistentLocalCache, persistentSingleTabManager,
  connectFirestoreEmulator,
} from 'firebase/firestore';

const FirestoreContext = createContext(null);

// Initialize Firestore as a singleton
let firestoreInstance = null;

function getFirestoreInstance(app) {
  if (firestoreInstance) {
    return firestoreInstance;
  }

  const baseSettings = process.env.NODE_ENV === 'development'
    ? {} // Empty settings for development as we'll use emulator
    : {
      localCache: persistentLocalCache({
        tabManager: persistentSingleTabManager({
          experimentalForceOwningTab: true,
          // forceOwnership for web worker
          // eslint-disable-next-line no-undef
          forceOwnership: !globalThis.localStorage,
        }),
      }),
    };

  // Initialize Firestore with appropriate settings
  firestoreInstance = initializeFirestore(app, baseSettings);

  // Connect to emulator in development environment
  const isDev = process.env.NODE_ENV === 'development';
  const isBrowser = typeof window !== 'undefined';
  const isLocalhost = isBrowser && window.location.hostname === 'localhost';

  if (isDev && isLocalhost) {
    connectFirestoreEmulator(firestoreInstance, 'localhost', 4401);
  }

  return firestoreInstance;
}

export function FirestoreProvider({ app, children }) {
  const firestore = getFirestoreInstance(app);

  return (
    <FirestoreContext.Provider value={firestore}>
      {children}
    </FirestoreContext.Provider>
  );
}

export const useFirestore = () => useContext(FirestoreContext);
