import { Route, Routes } from 'react-router-dom';
import './App.css';
import { Suspense } from 'react';
import LandingPage from './LandingPage';
import MainApp from './MainApp';
import NotFound from './components/NotFound';

function App() {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route
        path="/app/*"
        element={(
          <Suspense fallback={<div>Loading App...</div>}>
            <MainApp />
          </Suspense>
        )}
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
export default App;
