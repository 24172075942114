import {
  Box, Button, Paper, Step, StepButton, Stepper, Typography,
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { useFunctions } from 'firebaseHooks/FunctionsContext';
import { httpsCallable } from 'firebase/functions';
import WaitingForInviteInfo from './WaitingForInviteInfo';

export default function Action({
  t, ownerCollapse, acquirerCollapse, formalitiesCollapse,
  dossier, user, pageStatus, setPageStatus, sellerForm,
}) {
  const navigate = useNavigate();
  const functions = useFunctions();

  const steps = dossier.ownerType === 'seller' ? {
    seller_sellerFormCompleted: t('dossier_states.seller_sellerFormCompleted'),
    seller_acquirerFormCompleted: t('dossier_states.seller_acquirerFormCompleted'),
    seller_sellFormalitiesFormCompleted: t('dossier_states.seller_sellFormalitiesFormCompleted'),
  } : {
    acquirer_sellerFormCompleted: t('dossier_states.acquirer_sellerFormCompleted'),
    acquirer_acquirerFormCompleted: t('dossier_states.acquirer_acquirerFormCompleted'),
    acquirer_sellFormalitiesFormCompleted: t('dossier_states.acquirer_sellFormalitiesFormCompleted'),
  };

  const stateToStepMapping = {
    seller_draftPaymentSuccess: -1,
    seller_sellerFormCompleted: 0,
    seller_dossierSharedToAcquirer: 0,
    seller_acquirerAcceptedShare: 0,
    seller_acquirerFormCompleted: 1,
    seller_sellFormalitiesFormCompleted: 2,
    seller_dossierCompleted: 2,
    acquirer_draftPaymentSuccess: -1,
    acquirer_dossierSharedToSeller: -1,
    acquirer_sellerAcceptedShare: -1,
    acquirer_sellerFormCompleted: 0,
    acquirer_acquirerFormCompleted: 1,
    acquirer_sellFormalitiesFormCompleted: 2,
    acquirer_dossierCompleted: 2,
  };

  const activeStep = stateToStepMapping[dossier.state] === undefined ? -1 : stateToStepMapping[dossier.state];
  const completed = Object.keys(steps).map((_, index) => index <= activeStep);

  const scrollToRefWithOffset = (ref, offset) => {
    if (ref && ref.current) {
      const top = ref.current.getBoundingClientRect().top + window.pageYOffset - offset;
      window.scrollTo({ top, behavior: 'smooth' });
    }
  };

  const handleStep = (step) => () => {
    const offset = 80;
    const stepKeys = Object.keys(steps);

    switch (stepKeys[step]) {
      case 'seller_draftPaymentSuccess':
      case 'acquirer_draftPaymentSuccess':
      case 'seller_sellerFormCompleted':
      case 'acquirer_sellerFormCompleted':
        scrollToRefWithOffset(ownerCollapse, offset);
        break;
      case 'seller_dossierSharedToAcquirer':
      case 'acquirer_dossierSharedToSeller':
        scrollToRefWithOffset(acquirerCollapse, offset);
        break;
      case 'seller_acquirerFormCompleted':
      case 'acquirer_acquirerFormCompleted':
        scrollToRefWithOffset(acquirerCollapse, offset);
        break;
      case 'seller_sellFormalitiesFormCompleted':
      case 'acquirer_sellFormalitiesFormCompleted':
        scrollToRefWithOffset(formalitiesCollapse, offset);
        break;
      case 'seller_dossierCompleted':
      case 'acquirer_dossierCompleted':
        scrollToRefWithOffset(formalitiesCollapse, offset);
        break;
      default:
        break;
    }
  };

  const stepColor = (index) => {
    // Create a gradient from #4fde9c to #002b34 across the steps
    const colors = [
      '#4fde9c',
      '#3bb589',
      '#288d77',
      '#166666',
      '#033f54',
      '#002b34',
    ];
    return colors[index] || colors[colors.length - 1];
  };

  const showWaitingForSeller = () => user
    && (user.uid === dossier.acquirer)
    && ['acquirer_dossierSharedToSeller'].includes(dossier.state);

  const showWaitingForAcquirerToAcceptShare = () => user
    && sellerForm
    && (user.uid === dossier.seller)
    && ['seller_dossierSharedToAcquirer'].includes(dossier.state);

  const showWaitingForAcquirerToFillForm = () => user
    && (user.uid === dossier.seller)
    && ['seller_acquirerAcceptedShare'].includes(dossier.state);

  const showWaitingForSellerToFillForm = () => user
    && (user.uid === dossier.acquirer)
    && ['seller_acquirerFormCompleted', 'acquirer_sellerAcceptedShare'].includes(dossier.state);

  const showGoToSellerForm = () => user
    && (user.uid === dossier.seller)
    && ['seller_draftPaymentSuccess', 'acquirer_dossierSharedToSeller'].includes(dossier.state);

  const showGoToAcquirerForm = () => user
    && sellerForm
    && (user.uid === dossier.acquirer)
    && ['seller_acquirerAcceptedShare', 'acquirer_sellerFormCompleted'].includes(dossier.state);

  // console.log('dossier.state', dossier);
  // console.log('user.uid', user.uid);

  const showGoToSaleFormalitiesForm = () => user
    && (user.uid === dossier.seller)
    && ['seller_acquirerFormCompleted', 'acquirer_acquirerFormCompleted'].includes(dossier.state);

  const goToSellerForm = () => {
    navigate('formulaire-vendeur', {
      state: {
        dossierId: dossier.id,
        successState: 'seller_dossierSharedToAcquirer',
        acquirerEmail: dossier.acquirerEmail,
      },
    });
  };

  const goToAcquirerForm = () => {
    navigate('formulaire-acquereur', {
      state: {
        dossier,
        successState: 'seller_acquirerFormCompleted',
        sellerForm,
      },
    });
  };

  const goToSaleFormalitiesForm = () => {
    navigate('formulaire-formalites-vente', {
      state: {
        dossier,
        successState: 'seller_sellFormalitiesFormCompleted',
      },
    });
  };

  const showGenerateContractPdf = () => user
    && (user.uid === dossier.seller)
    && ['seller_sellFormalitiesFormCompleted', 'acquirer_sellFormalitiesFormCompleted'].includes(dossier.state);

  const handleGenerateContractPdf = async () => {
    try {
      setPageStatus('loading');
      const createContractAndStateAndMailsCall = httpsCallable(functions, 'createContractAndStateAndMailsCall');
      await createContractAndStateAndMailsCall({ dossierId: dossier.id });

      // Small delay to ensure the PDF is generated
      await new Promise((resolve) => { setTimeout(resolve, 5000); });

      navigate('contrat-pdf', {
        state: {
          dossier,
        },
      });
    } catch (error) {
      console.error('Error generating contract:', error);
      setPageStatus('error');
    }
  };

  const showContractPdf = () => user
    && (user.uid === dossier.seller || user.uid === dossier.acquirer)
    && ['seller_dossierCompleted', 'acquirer_dossierCompleted'].includes(dossier.state);

  const goToContractPdf = async () => {
    const storageDocPath = `gs://autonoom-ed849.appspot.com/dossiers/${dossier.id}/contrat-autonoom.pdf`;
    navigate('contrat-pdf', {
      state: {
        dossier,
        storageDocPath,
      },
    });
  };
  return (
    <Paper
      elevation={3}
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        zIndex: 9999,
        boxShadow: '0px -2px 10px rgba(0,0,0,0.1)',
        pb: 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Stepper
        nonLinear
        activeStep={activeStep}
        sx={{
          px: 1,
          py: 2,
          flexWrap: 'wrap',
          '& .MuiStep-root': {
            my: 1,
            flex: { xs: '1 1 50%', sm: '1 1 33.33%', md: 'none' },
          },
          '& .MuiStepConnector-root': {
            display: { xs: 'none', md: 'flex' },
          },
        }}
      >
        {Object.values(steps).map((label, index) => (
          <Step
            key={label}
            completed={completed[index]}
            sx={{
              '& .MuiStepLabel-root .Mui-completed': {
                color: stepColor(index),
              },
            }}
          >
            <StepButton
              color="inherit"
              onClick={handleStep(index)}
            >
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      {showGoToSellerForm() && (
        <Button
          color="primary"
          aria-label="completeSellerForm"
          onClick={goToSellerForm}
          variant="contained"
        >
          {t('contract.complete_seller_part')}
          <ArrowForwardIosIcon />
        </Button>
      )}
      {showGoToAcquirerForm() && (
        <Button
          color="primary"
          aria-label="completeAcquirerForm"
          onClick={goToAcquirerForm}
          variant="contained"
        >
          {t('contract.complete_acquirer_part')}
          <ArrowForwardIosIcon />
        </Button>
      )}
      {showGoToSaleFormalitiesForm() && (
        <Button
          variant="contained"
          color="primary"
          aria-label="completeFormalities"
          onClick={goToSaleFormalitiesForm}
        >
          {t('contract.finish_sale_formalities')}
          <ArrowForwardIosIcon />
        </Button>
      )}
      {showGenerateContractPdf() && (
        <LoadingButton
          loading={pageStatus === 'loading'}
          color="primary"
          aria-label="generatepdf"
          onClick={handleGenerateContractPdf}
          variant="contained"
          endIcon={<ArrowForwardIosIcon />}
        >
          <Box sx={{
            display: 'flex', flexDirection: 'column', mx: 3, my: 2,
          }}
          >
            <Typography>
              {t('contract.generate_contract')}
            </Typography>
          </Box>
        </LoadingButton>
      )}
      {showContractPdf() && (
        <>
          <Typography sx={{ fontWeigth: 'bold', mb: 1 }} variant="h6">
            Votre contrat est prêt
          </Typography>
          <LoadingButton
            loading={pageStatus === 'loading'}
            color="primary"
            aria-label="generatepdf"
            onClick={goToContractPdf}
            variant="contained"
            endIcon={<ArrowForwardIosIcon />}
          >
            <Box sx={{
              display: 'flex', flexDirection: 'column', mx: 3, my: 2,
            }}
            >

              <Typography>
                {t('state_display.vehicleSalePrivateIndividuals.contractSuccessfullyGenerated')}
              </Typography>
            </Box>
          </LoadingButton>
        </>
      )}

      {showWaitingForAcquirerToAcceptShare() && (
        <WaitingForInviteInfo
          dossier={dossier}
          recipientEmail={dossier.acquirerEmail}
          recipientField="acquirerEmail"
          senderName={sellerForm.values.seller.sellerType === 'individual'
            ? sellerForm.values.seller.individual.firstName + sellerForm.values.seller.individual.lastName
            : sellerForm.values.seller.entity.companyName}
        />
      )}
      {showWaitingForAcquirerToFillForm() && (
        <Typography sx={{ border: 1, borderRadius: 5, p: 3 }}>
          {t(
            'state_display.vehicleSalePrivateIndividuals.invitation_accepted_waiting',
          )}
        </Typography>
      )}
      {showWaitingForSellerToFillForm() && (
        <Typography sx={{ border: 1, borderRadius: 5, p: 3 }}>
          {t(
            'state_display.vehicleSalePrivateIndividuals.waiting_seller',
          )}
        </Typography>
      )}
    </Paper>
  );
}
