import React, {
  useRef, useState, useEffect, useCallback,
} from 'react';
import {
  Box, Button, Typography, Link as MUILink,
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import autonoom from 'files/logo-line-light.png';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import useGlobal from 'global-state/store';
import {
  getStorage,
} from 'firebase/storage';
import { useSignIn } from 'useSignIn/SingInContext';
import { getAnalytics } from 'firebase/analytics';
import { useAuth } from 'firebaseHooks/AuthContext';
import { useFirestore } from 'firebaseHooks/FirestoreContext';
import InformationsVehicle from './fields/InformationsVehicle';
import { validationSchema } from './validationSchema';
import VehicleState from './fields/VehicleState';
import VehicleMaintenance from './fields/VehicleMaintenance';
import VehicleSaleDetails from './fields/VehicleSaleDetails';
import SellerDetails from './fields/SellerDetails';
import FormMap from './FormMap';
import ScrollToFieldError from '../ScrollToFieldError';
import submitForm from './submitForm';
import ScrollTracker from './ScrollTracker';
import SharedSaleFormEndDialog from './SharedSaleFormEndDialog';
import SavedFormsDialog, { saveFormToFirestore } from './SavedFormsDialog';

export default function SharedSaleForm({
  mode, initialValues, onFormStateChange, dossierId, acquirerEmail, successState,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const vehicleSection = useRef(null);
  const vehicleSection2 = useRef(null);
  const vehicleSection3 = useRef(null);
  const vehicleSaleDetailsSection = useRef(null);
  const sellerSection = useRef(null);
  const isSmallScreen = window.innerWidth < 1300;
  const [mapOpen, setMapOpen] = useState(!isSmallScreen);
  const [startScrollY, setStartScrollY] = useState(window.scrollY);
  const [scrolledDistance, setScrolledDistance] = useState(0);
  const scrollDistanceThreshold = 1000;
  const [scrollToErrorActive, setScrollToErrorActive] = useState(false);
  const firestore = useFirestore();
  const [continueDialogOpen, setContinueDialogOpen] = useState(false);
  const [pageStatus, setPageStatus] = useState('initial');
  const { currentUser: user } = useAuth();
  const storage = getStorage();
  const [, globalActions] = useGlobal();
  const { openSignin } = useSignIn();
  const analytics = getAnalytics();
  const isReadOnly = mode === 'readonly';
  const [bothPartiesPresent, setBothPartiesPresent] = useState(false);
  const [buyerEmail, setBuyerEmail] = useState(acquirerEmail);
  const [savedFormsDialogOpen, setSavedFormsDialogOpen] = useState(false);

  const sectionRefs = [
    { name: 'vehicleSection', ref: vehicleSection },
    { name: 'vehicleSection2', ref: vehicleSection2 },
    { name: 'vehicleSection3', ref: vehicleSection3 },
    { name: 'vehicleSaleDetailsSection', ref: vehicleSaleDetailsSection },
    { name: 'sellerSection', ref: sellerSection },
  ];

  const [activeSection, setActiveSection] = useState('vehicleSection');

  const handleActiveSectionChange = (currentSection) => {
    setActiveSection(currentSection);
  };

  const updateScrolling = useCallback(() => {
    const currentScrollY = window.scrollY;

    // Set start scrolling position if it hasn't been set yet.
    if (startScrollY === null) {
      setStartScrollY(currentScrollY);
      setScrolledDistance(0); // Reset scrolled distance.
    } else {
      const newScrolledDistance = scrolledDistance + Math.abs(currentScrollY - startScrollY);
      setScrolledDistance(newScrolledDistance);
      setStartScrollY(currentScrollY); // Update the start position.

      if (newScrolledDistance >= scrollDistanceThreshold) {
        setMapOpen(true); // Open the map.
        setStartScrollY(null); // Reset start scroll Y for next open.
        setScrolledDistance(0); // Reset scrolled distance.
      }
    }
  }, [startScrollY, scrolledDistance]);

  useEffect(() => {
    const handleScroll = () => {
      updateScrolling();
    };
    if (isSmallScreen) {
      window.addEventListener('scroll', handleScroll);
    }

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isSmallScreen, updateScrolling]);

  const closeMap = () => {
    if (isSmallScreen) {
      setMapOpen(false);
    }
  };

  const handleFormSubmit = () => {
    const prettyPrinted = JSON.stringify(formik.values, null, 2);
    console.log('prettyPrinted: ', prettyPrinted);
    setScrollToErrorActive(true);
    formik.submitForm();
  };

  const createModeSubmit = (values) => {
    if (!user?.uid) {
      openSignin(() => () => setContinueDialogOpen(true));
    } else {
      saveFormToFirestore('savedSellerForms', formik.values, user.uid, firestore);
      setContinueDialogOpen(true);
    }
  };

  const editModeSubmit = (values) => {
    try {
      submitForm(
        {
          mode,
          formik,
          storage,
          firestore,
          setPageStatus,
          user,
          buyerEmail,
          t,
          navigate,
          analytics,
          globalActions,
          clearFormStorage,
          dossierId,
          successState,
        },
      );
    } catch (error) {
      globalActions.setSnackbarMessage({ message: `${t('unexpected_error')} ${error}`, severity: 'error' });
      throw error;
    }
  };

  const handleCreateDialogSubmit = () => {
    try {
      submitForm(
        {
          mode,
          formik,
          storage,
          firestore,
          setPageStatus,
          user,
          buyerEmail,
          t,
          navigate,
          analytics,
          globalActions,
          clearFormStorage,
          dossierId,
          successState,
        },
      );
    } catch (error) {
      globalActions.setSnackbarMessage({ message: `${t('unexpected_error')} ${error}`, severity: 'error' });
      throw error;
    }
  };

  const handleSubmit = (values) => {
    if (mode === 'create') {
      createModeSubmit(values);
    } else {
      editModeSubmit(values);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema(t),
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (onFormStateChange) {
      onFormStateChange(formik.values);
    }
  }, [formik.values, onFormStateChange]);

  const clearFormStorage = () => {
    sessionStorage.removeItem('saleForm');
  };

  const scrollToRef = (ref) => {
    window.scrollTo({
      top: ref.current.offsetTop,
      behavior: 'smooth',
    });
  };

  return (
    <Box>
      {!isReadOnly && (
        <>
          <FormMap
            mapOpen={mapOpen}
            setMapOpen={setMapOpen}
            scrollToRef={scrollToRef}
            vehicleSection={vehicleSection}
            vehicleSection2={vehicleSection2}
            vehicleSection3={vehicleSection3}
            vehicleSaleDetailsSection={vehicleSaleDetailsSection}
            sellerSection={sellerSection}
            isSmallScreen={isSmallScreen}
            activeSection={activeSection}
          />
          <ScrollTracker sectionRefs={sectionRefs} onActiveSectionChange={handleActiveSectionChange} />
        </>
      )}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 10,
          mt: 2,
          p: 1,
          width: { xs: '100%', sm: '90vw' },
          maxWidth: 800,
        }}
        component="form"
        onSubmit={formik.handleSubmit}
        onClick={closeMap}
      >
        <ScrollToFieldError
          formik={formik}
          scrollToErrorActive={scrollToErrorActive}
          setScrollToErrorActive={setScrollToErrorActive}
          formBaseKey="saleForm"
          orderedKeys={[
            'vehicle', 'vehicleSaleDetails', 'seller',
            'nonProfessionalSellerCertification', 'saleFormalities', 'contractTerms',
          ]}
        />
        {mode === 'create' && !bothPartiesPresent && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              alignItems: 'center',
            }}
          >
            <Box
              component="img"
              sx={{
                width: 130, height: 'auto', cursor: 'pointer',
              }}
              src={autonoom}
              alt="Autonoom Logo"
              onClick={() => navigate('/')}
            />
            <Typography sx={{
              fontWeight: 'bold',
              color: 'primary.main',
            }}
            >
              {t('vehicle_sale_contract')}
            </Typography>
            <Typography variant="subtitle2" sx={{ textAlign: 'left', mt: 3 }}>
              <Trans i18nKey="vehicle_sale_info_1">
                <MUILink
                  component={Link}
                  to="/app/dossiers"
                  color="primary"
                  sx={{ fontWeight: 'bold' }}
                >
                  mes contrats
                </MUILink>
              </Trans>
            </Typography>
            <Typography>{t('')}</Typography>

          </Box>
        )}
        <Box ref={vehicleSection}>
          <InformationsVehicle
            formik={formik}
            isReadOnly={isReadOnly}
          />
        </Box>
        <Box ref={vehicleSection2}>
          <VehicleState formik={formik} isReadOnly={isReadOnly} />
        </Box>
        <Box ref={vehicleSection3}>
          <VehicleMaintenance formik={formik} isReadOnly={isReadOnly} />
        </Box>
        <Box ref={vehicleSaleDetailsSection}>
          <VehicleSaleDetails formik={formik} isReadOnly={isReadOnly} />
        </Box>
        <Box ref={sellerSection}>
          <SellerDetails formik={formik} isReadOnly={isReadOnly} />
        </Box>
        {mode === 'create' && (
          <Button onClick={handleFormSubmit} variant="contained">
            {t('continue')}
          </Button>
        )}
        {mode === 'edit' && (
          <LoadingButton loading={pageStatus === 'loading'} onClick={handleFormSubmit} variant="contained">
            {t('modify')}
          </LoadingButton>
        )}

      </Box>
      <SharedSaleFormEndDialog
        handleSubmit={handleCreateDialogSubmit}
        continueDialogOpen={continueDialogOpen}
        setContinueDialogOpen={setContinueDialogOpen}
        pageStatus={pageStatus}
        buyerEmail={buyerEmail}
        setBuyerEmail={setBuyerEmail}
        bothPartiesPresent={bothPartiesPresent}
        setBothPartiesPresent={setBothPartiesPresent}
      />
      {mode === 'create' && (
        <SavedFormsDialog
          open={savedFormsDialogOpen}
          setOpen={setSavedFormsDialogOpen}
          formik={formik}
          docName="savedSellerForms"
        />
      )}
    </Box>
  );
}
