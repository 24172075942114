import React from 'react';
import './App.css';
import 'fontsource-roboto';
import {
  ThemeProvider,
  StyledEngineProvider,
  styled,
  createTheme,
} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { initializeApp, getApps, getApp } from 'firebase/app';
import { AuthProvider } from 'firebaseHooks/AuthContext';
import { RemoteConfigProvider } from 'firebaseHooks/RemoteConfigContext';
import { frFR } from '@mui/x-date-pickers/locales';
import { frFR as coreFrFR } from '@mui/material/locale';
import { AnalyticsProvider } from 'firebaseHooks/AnalyticsContext';
import { FirestoreProvider } from 'firebaseHooks/FirestoreContext';
import { FunctionsProvider } from 'firebaseHooks/FunctionsContext';
import { StorageProvider } from 'firebaseHooks/StorageContext';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { connectStorageEmulator, getStorage } from 'firebase/storage';
import Home from 'components/Home';
import { initI18next } from './i18n/i18next';

const firebaseConfig = {
  apiKey: 'AIzaSyBYht6xfzT5RGcW0j3Zb3AxsTZnH2cQmrw',
  authDomain: 'autonoom.fr',
  projectId: 'autonoom-ed849',
  storageBucket: 'autonoom-ed849.appspot.com',
  messagingSenderId: '66337130865',
  appId: '1:66337130865:web:d09e26092db35c8f2fced0',
  measurementId: 'G-K04LPDL58L',
};

// Initialize Firebase only once
const app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApp();

// Connect to emulators only in development and browser environment
if (process.env.NODE_ENV === 'development' && typeof window !== 'undefined') {
  const auth = getAuth(app);
  const functions = getFunctions(app);
  const storage = getStorage(app);

  if (window.location.hostname === 'localhost') {
    connectAuthEmulator(auth, 'http://localhost:9099');
    connectFunctionsEmulator(functions, 'localhost', 5001);
    connectStorageEmulator(storage, 'localhost', 9199);
  }
}

const theme = createTheme(
  {
    palette: {
      primary: {
        main: '#14c7bf',
        contrastText: 'white',
      },
      secondary: {
        main: '#4fde9c',
        contrastText: 'white',
      },
      info: {
        main: '#002b34',
      },
      text: {
        primary: '#09524e',
      },
      gold: {
        main: '#FFD700',
        contrastText: 'white',
      },
      darkgray: {
        main: '#0000008a',
        contrastText: 'white',
      },
      mediumgray: {
        main: '#8585858a',
        contrastText: 'white',
      },
      gray: {
        main: '#d8d8d8',
        contrastText: 'white',
      },
      lightGray: {
        main: '#f5f5f7',
        contrastText: 'black',
      },
      link: {
        main: '#109993',
        contrastText: 'white',
      },
    },
  },
  frFR,
  coreFrFR,
);

const GlobalStyle = styled('div')(({ theme: t }) => ({
  backgroundColor: t.palette.background.default,
  width: '100%',
  minHeight: '100vh',
  position: 'absolute',
  top: 0,
  left: 0,
}));

function MainApp() {
  initI18next();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <GlobalStyle>
          <>
            <CssBaseline />
            <AuthProvider app={app}>
              <RemoteConfigProvider app={app}>
                <AnalyticsProvider app={app}>
                  <FirestoreProvider app={app}>
                    <FunctionsProvider app={app}>
                      <StorageProvider app={app}>
                        <Home />
                      </StorageProvider>
                    </FunctionsProvider>
                  </FirestoreProvider>
                </AnalyticsProvider>
              </RemoteConfigProvider>
            </AuthProvider>
          </>
        </GlobalStyle>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default MainApp;
