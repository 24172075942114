import React, { useEffect } from 'react';
import {
  Box, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from 'firebaseHooks/AuthContext';
import useFirestoreDocumentData from 'firebaseHooks/useFirestoreDocumentData';
import LoadingPage from 'components/LoadingPage';
import PrivateVehicleTransactionDossier from './PrivateVehicleTransactionDossier';

export default function DossierPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { currentUser: user } = useAuth();
  const { dossierId } = useParams();

  const { data: dossier, loadingState: dossierLoadingState } = useFirestoreDocumentData(`dossiers/${dossierId}`);

  useEffect(() => {
    if (dossierLoadingState === 'error' || (dossierLoadingState === 'success' && !dossier)) {
      navigate('/app/dossiers');
    }
  }, [navigate, dossier, dossierLoadingState]);

  if (dossierLoadingState === 'loading' || !dossier) {
    return <LoadingPage />;
  }

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2,
    }}
    >
      <Typography variant="h6" sx={{ maxWidth: '80vw' }}>
        {t('contract.overview')}
      </Typography>
      {user && (
        <PrivateVehicleTransactionDossier
          dossier={dossier}
          user={user}
        />
      )}
    </Box>
  );
}
