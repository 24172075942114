import { useState, useEffect, useCallback } from 'react';
import { doc, getDoc, onSnapshot } from 'firebase/firestore';
import { useFirestore } from './FirestoreContext';

const useFirestoreDocumentData = (documentPath, options = {}) => {
  const firestore = useFirestore();
  const [data, setData] = useState(null);
  const [loadingState, setLoadingState] = useState('initial');
  const [error, setError] = useState(null);

  const fetchDataOnce = useCallback(async () => {
    setLoadingState('loading');
    if (typeof documentPath !== 'string') {
      const errorMessage = `Invalid documentPath: expected a string but got ${typeof documentPath}`;
      setLoadingState('error');
      throw new Error(errorMessage);
    }
    try {
      const docRef = doc(firestore, documentPath);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setData({
          [options.idField || 'id']: docSnap.id,
          ...docSnap.data(),
        });
      } else {
        setData(null);
      }
      setLoadingState('success');
    } catch (err) {
      setError(err);
      setLoadingState('error');
      throw err;
    }
  }, [documentPath, firestore, options.idField]);

  const subscribeToUpdates = useCallback(() => {
    if (typeof documentPath !== 'string') {
      const errorMessage = `Invalid documentPath: expected a string but got ${typeof documentPath}`;
      setLoadingState('error');
      throw new Error(errorMessage);
    }

    try {
      const docRef = doc(firestore, documentPath);
      const unsubscribe = onSnapshot(docRef, (snapshot) => {
        if (snapshot.exists()) {
          setData({
            [options.idField || 'id']: snapshot.id,
            ...snapshot.data(),
          });
        } else {
          setData(null);
        }
        setLoadingState('success');
      }, (err) => {
        setError(err);
        setLoadingState('error');
      });

      return unsubscribe;
    } catch (err) {
      setError(err);
      setLoadingState('error');
      throw err;
    }
  }, [documentPath, firestore, options.idField]);

  useEffect(() => {
    let unsubscribe;
    if (loadingState === 'initial') {
      fetchDataOnce().then(() => {
        unsubscribe = subscribeToUpdates();
      });
    }
    return () => unsubscribe && unsubscribe();
  }, [documentPath, fetchDataOnce, subscribeToUpdates, firestore, loadingState]);

  return { data, loadingState, error };
};

export default useFirestoreDocumentData;
