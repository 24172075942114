import './index.css';
import { StrictMode } from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import initializeSentry from 'components/SentrySetup';
import App from './App';

initializeSentry();

function AppWithRouter() {
  return (
    <StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </StrictMode>
  );
}

const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
  hydrateRoot(rootElement, <AppWithRouter />);
} else {
  const root = createRoot(rootElement);
  root.render(<AppWithRouter />);
}
