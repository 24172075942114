import React from 'react';
import { Box, Typography } from '@mui/material';

export default function SSRFooter() {
  return (
    <Box
      component="footer"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        mt: 5,
        mb: 3,
      }}
    >
      <Typography component="p" variant="caption">
        contact@autonoom.com
      </Typography>
    </Box>
  );
}
