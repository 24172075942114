import React from 'react';
import {
  Box, Typography, Button, Link as MuiLink,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import autonoom from 'files/logo_pour_fond_clair.svg';
import dots from 'files/dots.png';
import { useTheme } from '@emotion/react';
import profile1 from 'files/portrait-01.png';
import profile2 from 'files/portrait-02.png';
import profile3 from 'files/portrait-03.png';
import laboxlogo from 'files/laboxlogo.jpg';
import barreaunantes from 'files/barreaunantes.png';
import visuelCoupe from 'files/visuel_coupe_pour_fond_clair-1000px.png';
import HighlightedText from './HighlightedText';

function AutonoomPresentation() {
  const theme = useTheme();

  const preloadApp = () => {
    import('../MainApp');
    import('firebase/app');
  };

  const StyledLink = styled(Link)({
    textDecoration: 'none',
  });

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        minHeight: '100%',
        gap: 6,
        width: '100%',
      }}
    >
      <Box sx={{
        textAlign: 'center',
        pb: 1,
        width: '100%',
        mt: 0,
        maxWidth: { xs: 200, md: 300 },
      }}
      >
        <img style={{ width: '100%', height: 'auto' }} src={autonoom} alt="Autonoom Logo" />
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 6,
        px: 2,
        maxWidth: 1000,
      }}
      >
        <HighlightedText
          text="Votre achat ou vente de véhicule, pour 10€"
          sx={{
            color: '#09524e',
            fontWeight: 'bold',
            textAlign: 'center',
            fontSize: {
              xs: theme.typography.h6.fontSize,
              md: theme.typography.h4.fontSize,
            },
          }}
        />
        <HighlightedText
          text={`Dossier complet et contrat rédigés directement sur l'application par les 
            deux avocats les plus sympas de France.`}
          highlights={['Dossier', 'contrat', 'avocats']}
          sx={{
            color: '#09524e',
            mt: 6,
            fontSize: {
              xs: theme.typography.h6.fontSize,
              md: theme.typography.h5.fontSize,
            },
          }}
        />
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        pb: 15,
        pt: 15,
        width: '100%',
        backgroundColor: '#f5f5f7',
        position: 'relative',
        minHeight: 100,
        mt: 3,
      }}
      >
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          zIndex: 1,
          flexWrap: 'wrap',
        }}
        >
          <StyledLink to="/app/dossiers/creation-vente" onMouseEnter={preloadApp}>
            <Button variant="contained" color="primary" size="large">
              Je vend mon véhicule
            </Button>
          </StyledLink>
          <Button variant="contained" color="primary" size="large" disabled>
            J&apos;acquiers un véhicule
          </Button>
        </Box>
        <Box
          component="img"
          sx={{
            width: { xs: 60, sm: 100 },
            height: 'auto',
            position: 'absolute',
            right: 0,
          }}
          src={visuelCoupe}
          alt="visuelCoupe"
        />
        <Typography
          sx={{
            color: 'primary.main',
            textAlign: 'center',
            fontStyle: 'italic',
            position: 'absolute',
            fontWeight: 'bold',
            bottom: 0,
            width: '100%',
            fontSize: {
              xs: theme.typography.body2.fontSize,
              md: theme.typography.body1.fontSize,
            },
          }}
        >
          Pour des transactions simples et sécurisées entre particuliers
        </Typography>
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        pb: 5,
        px: 5,
        width: '100%',
        justifyContent: 'flex-start',
        mt: 12,
      }}
      >
        <Box
          component="img"
          sx={{
            width: { xs: 40, md: 100 },
            height: 'auto',
            display: { xs: 'none', md: 'block' },
          }}
          src={dots}
          alt="dots"
        />
        <Box sx={{
          alignSelf: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 3,
          textAlign: 'center',
          ml: { xs: 0, md: -12 },
          flexGrow: 1,
        }}
        >
          {/* <Typography
            sx={{
              fontWeight: 'bold',
              fontSize: {
                xs: theme.typography.h6.fontSize,
                md: theme.typography.h6.fontSize,
              },
            }}
          >
            Bienvenue !
          </Typography> */}
          <MuiLink
            sx={{
              maxWidth: {
                xs: '100%', md: 600, lg: 800, xl: 1200,
              },
              fontWeight: 'bold',
              mx: 1,
              fontSize: {
                xs: theme.typography.h6.fontSize,
                md: theme.typography.h4.fontSize,
              },
            }}
            href="https://www.informateurjudiciaire.fr/actualites/ces-avocats-qui-sont-aussi-des-entrepreneurs/"
            target="_blank"
            color="link.main"
          >
            AUTONOOM gagne le prix du Jury de l&apos;Incubateur du Barreau de Nantes
          </MuiLink>
          <Typography>
            Et ce n&apos;est que le début...
          </Typography>
        </Box>
      </Box>

      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        pt: 5,
        pb: 5,
        gap: 8,
        backgroundColor: '#f5f5f7',
        alignItems: 'center',
      }}
      >
        <Typography sx={{
          fontWeight: 'bold',
          fontSize: {
            xs: theme.typography.h6.fontSize,
            md: theme.typography.h4.fontSize,
          },
        }}
        >
          L&apos;Équipe
        </Typography>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          alignItems: 'center',
          gap: { xs: 1, md: 6 },
          justifyContent: 'center',
        }}
        >
          <Profile
            profileImg={profile1}
            name="Noémie CHANSON"
            title="Avocate fondatrice"
            link="https://www.linkedin.com/in/no%C3%A9mie-chanson-a1089498/"
          />
          <Profile
            profileImg={profile2}
            name="Alexis ROYER"
            title="Avocat fondateur"
            link="https://www.linkedin.com/in/alexis-royer-350539180/"
          />
          {/* <Profile
            profileImg={profile3}
            name="Antoine AURIBAULT"
            title="Associé fondateur"
            link="https://www.linkedin.com/in/antoineauribault/"
          /> */}
        </Box>
      </Box>

      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        pt: 5,
        pb: 5,
        gap: 8,
      }}
      >
        <Typography sx={{
          fontWeight: 'bold',
          fontSize: {
            xs: theme.typography.h6.fontSize,
            md: theme.typography.h4.fontSize,
          },
        }}
        >
          Les partenaires
        </Typography>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          alignItems: 'center',
          gap: { xs: 1, md: 6 },
          justifyContent: 'center',
        }}
        >
          <Profile
            profileImg={laboxlogo}
            name="Labox Applications"
            title="Les développeurs"
            link="https://www.labox-apps.com"
          />
          <Profile
            profileImg={barreaunantes}
            name="Barreau de Nantes"
            title="L'incubateur"
            link="https://www.barreaunantes.fr"
          />
          <Profile profileImg={profile2} name="Greg" title="La love monnaie" />
        </Box>
      </Box>
    </Box>
  );
}

function Profile({
  profileImg, name, title, link,
}) {
  const theme = useTheme();

  const content = (
    <>
      <Box
        component="img"
        sx={{
          width: 'auto', height: 200,
        }}
        src={profileImg}
        alt={name}
      />
      <Typography sx={{
        fontWeight: 'bold',
        color: 'link.main',
        fontSize: {
          xs: theme.typography.subtitle1.fontSize,
          md: theme.typography.h4.fontSize,
        },
      }}
      >
        {name}
      </Typography>
      <Typography sx={{ color: 'text.primary' }}>
        {title}
      </Typography>
    </>
  );

  if (link) {
    return (
      <MuiLink href={link} target="_blank" rel="noopener noreferrer" sx={{ textDecoration: 'none' }}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 1,
          minWidth: 300,
        }}
        >
          {content}
        </Box>
      </MuiLink>
    );
  }

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 1,
      minWidth: 300,
    }}
    >
      {content}
    </Box>
  );
}

export default AutonoomPresentation;
