import React from 'react';
import Box from '@mui/material/Box';
import { CircularProgress } from '@mui/material';
import Footer from './Footer';

export default function LoadingPage() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <CircularProgress />
      <Footer minimal />
    </Box>
  );
}
